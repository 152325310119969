import React from 'react';
import CardsItemPodcast from './CardsItemPodcast';
import './podcast.css'

function PodcastList() {
    const podcasts = [
        {
            name: "Episódio 1: A Jornada de Berzebuey e a Busca pela Sabedoria",
             src: "https://bucket.server.umahistoriadapeninsula.com/podcast/PodcastCapaPrimeiroEpisódio.jpg",
            path: "/PrimeiroEpisodio", // Caminho para a página de detalhes
            label: "Episódio 1",
        },
        {
            name: "Episódio 2: O Gato e o Rato",
             src: "https://bucket.server.umahistoriadapeninsula.com/podcast/Capa_Podcast_Episodio2.png",
            path: "/SegundoEpisodio", // Caminho para a página de detalhes
            label: "Episódio 2",
        },
        {
            name: "Episódio 3: Entre a Fábula e a Moralidade: uma investigação sobre Calila e Dimna",
             src: "https://bucket.server.umahistoriadapeninsula.com/podcast/capa_podcast_3.png",
            path: "/TerceiroEpisodio", // Caminho para a página de detalhes
            label: "Episódio 3",
        },

        {
            name: "Episódio 1: História da Península e Trajetórias de Pesquisa",
             src: "https://bucket.server.umahistoriadapeninsula.com/podcast/capa_podcast_1a.jpg",
            path: "/PrimeiroEpisodioHistóriadaPenínsula", // Caminho para a página de detalhes
            label: "Episódio 1",
        },
    ];

    return (
        <ul className='cards__items_podcast'>
            {podcasts.map((podcast, index) => (
                <CardsItemPodcast
                    key={index}
                    name={podcast.name}
                    text={podcast.text}
                    src={podcast.src}
                    path={podcast.path}
                    label={podcast.label}
                />
            ))}
        </ul>
    );
}

export default PodcastList;
